import { Collectible } from '@1kinlabs/ui-core/types/Collectible'
import { Claim as ClaimV1 } from '@1kinlabs/ui-core/types/Claim'
import { ClaimStatus as ClaimStatusV1 } from '@1kinlabs/ui-core/enums/ClaimStatus'
import { Game as LegacyGame } from '@1kinlabs/ui-core/types/Game'
import {
  ClaimMethod,
  Reward,
  Claim as ClaimV2,
  ClaimStatus as ClaimStatusV2,
  Game,
  Status,
} from './types'

export type CollectibleBlopBlop = Omit<Collectible, 'game'> & { game: LegacyGameBlopBlop }

export function transformRewardToCollectible(reward: Reward, game: Game): CollectibleBlopBlop {
  return {
    // @ts-expect-error - reward is not actually on Collectible (just want it for debugging)
    reward,
    id: reward.id,
    title: reward.name,
    description: reward.description,
    short_description: reward.description,
    claim_instructions: reward.claimInstructions ?? '',
    faq_list: reward.faqList ?? [],
    item_details: reward.itemDetails ?? [],
    auto_generate_claim_code: reward.claimMethod === ClaimMethod.CODE_AUTO.toString(),
    total_count: reward.claimLimit,
    available_codes: Math.max(reward.claimLimit - reward.totalClaimed, 0),
    claimed_count: reward.totalClaimed,
    claimStatus: transformClaimStatusV2toClaimStatusV1(reward.claim?.status, reward.status),
    claim: reward.claim ? transformClaimV2toClaimV1(reward.claim) : undefined,
    status: reward.status,
    game: transformGameToLegacyGame(game),
    require_complete_by_client: !reward.isAutoCompleted,
    createdAt: reward.createdAt.toString(),
    updatedAt: reward.updatedAt?.toString() ?? '',
    end_date: reward.endDate?.toString() ?? '',
    start_date: reward.startDate?.toString() ?? '',
    required_link_before_claim: false, // unused
    collectible_uid: 'DEPRECATED(collectible_uid)', // unused
    created_by_id: 0, // unused
    updated_by_id: 0, // unused
    is_test: false, // unused
    claim_type: 'DEPRECATED(claim_type)', // unused
    // TODO: api-server | claimStatus fr
    asset_type: 'DEPRECATED(asset_type)', // unused
    featured: reward.isFeatured, // unused
    game_collectible_id: 'DEPRECATED(game_collectible_id)', // unused
    game_engine_support: [], // unused
    onchain_token_id: 0, // unused
    is_collectible: true, // unused
    assets: {
      cardMedia: {
        defaultMedia: {
          src1x: reward.featuredImage,
          src2x: reward.featuredImage,
        },
      },
      heroMedia: {
        defaultMedia: {
          src1x: reward.featuredImage,
          src2x: reward.featuredImage,
        },
      },
      detailImage: {
        defaultMedia: {
          src1x: reward.featuredImage,
          src2x: reward.featuredImage,
        },
      },
      featureImage: reward.featuredImage,
      thumbnailImage: {
        defaultMedia: {
          src1x: reward.featuredImage,
          src2x: reward.featuredImage,
        },
      },
      additionalMedia: reward.additionalMedia ?? [],
    },
    costInCredits: reward.costInCredits,
  }
}

export type LegacyGameBlopBlop = LegacyGame & { accountId: number, status: Status }

export function legacyGameToGameV2(game: LegacyGameBlopBlop): Omit<Game, 'legacy' | 'status' | 'totalRewardCount'> {
  return {
    id: game.id,
    name: game.title,
    description: game.description,
    gameModes: game.game_modes.map((gm) => gm.toString()) as Game['gameModes'],
    genres: game.genres.map((g) => g.toString()) as Game['genres'],
    platforms: game.platforms.map((p) => p.toString()) as Game['platforms'],
    publisher: game.publisher,
    minAge: game.min_age,
    releaseDate: new Date(game.release_date),
    coverImage: game.cover_art.defaultMedia.src1x,
    liveRewardCount: game.liveContent,
    claimedRewardCount: game.claims.totalContent,
    liveClaimedRewardCount: game.claims.liveContent,
    faqList: game.faq_list,
    website: game.official_website,
    url: game.account_link_config?.linkingUrl,
    email: game.official_email,
    accountId: game.accountId,
    createdAt: game.createdAt ? new Date(game.createdAt) : new Date(),
    updatedAt: game.updatedAt ? new Date(game.updatedAt) : undefined,
    deletedAt: game.deleted ? new Date() : null,
  }
}

export function transformGameToLegacyGame(game: Game): LegacyGameBlopBlop {
  return {
    id: game.id,
    title: game.name,
    description: game.description,
    game_modes: (game.gameModes?.map((gm) => gm.toString()) ?? []) as LegacyGame['game_modes'],
    genres: (game.genres?.map((g) => g.toString()) ?? []) as LegacyGame['genres'],
    platforms: (game.platforms?.map((p) => p.toString()) ?? []) as LegacyGame['platforms'],
    publisher: game.publisher,
    min_age: game.minAge ?? 0,
    release_date: game.releaseDate?.toString() ?? '',
    cover_art: getMedia(game.coverImage),
    liveContent: game.liveRewardCount,
    claims: {
      totalContent: game.claimedRewardCount,
      liveContent: game.liveClaimedRewardCount,
    },
    faq_list: game.faqList ?? [],
    official_website: game.website ?? '',
    account_link_config: { linkingUrl: game.url ?? game.website ?? '' },
    createdAt: game.createdAt.toString(),
    updatedAt: game.updatedAt?.toString() ?? '',
    deleted: game.deletedAt !== null,
    official_email: game.email ?? '',
    other_developers: [], // deprecated
    onchain_game_id: 0, // deprecated
    game_uid: 'DEPRECATED(game_uid)', // deprecated
    primary_developer: game.publisher, // deprecated
    vendor_icon: getMedia(''), // deprecated
    additional_media: [], // deprecated
    created_by_id: 0, // deprecated
    updated_by_id: 0, // deprecated
    person_name: 'DEPRECATED(person_name)', // deprecated
    disabled: false, // deprecated
    accountId: game.accountId,
    status: game.status,
  }
}

function getMedia(src: string): { defaultMedia: { src1x: string; src2x: string } } {
  return {
    defaultMedia: {
      src1x: src,
      src2x: src,
    },
  }
}

export function transformClaimV2toClaimV1(claim: ClaimV2 | undefined): ClaimV1 | undefined {
  if (!claim) {
    return undefined
  }
  return {
    id: claim.id,
    claim_code: claim.code,
    status: transformClaimStatusV2toClaimStatusV1(claim.status),
    uid: 'DEPRECATED(claim.uid)', // unused
  }
}

export function transformClaimStatusV2toClaimStatusV1(
  claimStatus: ClaimStatusV2 | undefined,
  rewardStatus?: Status | undefined,
): ClaimStatusV1 {
  if (rewardStatus === Status.SOLD_OUT) {
    return ClaimStatusV1.SOLD_OUT
  }
  if (rewardStatus === Status.EXPIRED) {
    return ClaimStatusV1.EXPIRED
  }
  if (claimStatus === ClaimStatusV2.AVAILABLE) {
    return ClaimStatusV1.AVAILABLE
  }
  if (claimStatus === ClaimStatusV2.IN_PROGRESS) {
    return ClaimStatusV1.IN_PROGRESS
  }
  if (claimStatus === ClaimStatusV2.COMPLETED) {
    return ClaimStatusV1.COMPLETED
  }

  return ClaimStatusV1.AVAILABLE
}
